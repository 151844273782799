<template>
  <div id="data-list-list-view" class="data-list-container">
    <vs-row style="flex-direction: row-reverse;" class="mb-2">
      <vs-button @click="addVariant" icon-pack="feather">Add Variant</vs-button>
    </vs-row>
    <vs-table ref="table" :sst="true" :data="variants" :noDataText="noDataText">
      <template slot="thead">
        <vs-th>Name</vs-th>
        <vs-th>Description</vs-th>
        <vs-th>Images</vs-th>
        <vs-th style="display: flex; justify-content: center;">Actions</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].name">{{
            data[indextr].name || "N/A"
          }}</vs-td>
          <vs-td :data="data[indextr].description">{{
            data[indextr].description || "N/A"
          }}</vs-td>
          <vs-td :data="data[indextr]._id">{{
            data[indextr].images.length + " Images"
          }}</vs-td>
          <vs-td :data="data[indextr]._id">
            <vx-tooltip
              style="display: flex; align-items: center; justify-content: center;"
              text="View/Edit Product Variant"
              class="mb-2"
            >
              <vs-button
                @click="editVariant(indextr)"
                type="border"
                size="small"
                icon-pack="feather"
                icon="icon-edit"
                class="mr-2"
              ></vs-button>
            </vx-tooltip>

            <vx-tooltip
              style="display: flex; align-items: center; justify-content: center;"
              text="Delete Product Variant"
            >
              <vs-button
                @click="openConfirm(data[indextr]._id)"
                type="border"
                size="small"
                icon-pack="feather"
                icon="icon-trash"
                class="mr-2"
              ></vs-button>
            </vx-tooltip>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <EditProductVariantImages
      @closePopup="closePopup"
      @addVariantImages="addVariantImages"
      @submitVariant="submitVariant"
      @removeProductImage="removeProductImage"
      :popupActivo5="popupActivo5"
      :productId="productId"
      :variant="variant"
      :task="task"
      :showCustomError="showCustomError"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import EditProductVariantImages from "./EditProductVariantImages";
import axios from "@/axios";

export default {
  components: {
    EditProductVariantImages
  },
  data() {
    return {
      variants: [],
      noDataText: "Loading...",
      popupActivo5: false,
      variant: null,
      task: "create",
      showCustomError: false,
      variantIdToDelete: null
    };
  },
  methods: {
    ...mapActions("storeProducts", [
      "findProductById",
      "updateProductVariant",
      "deleteProductVariant"
    ]),
    fetchRequiredModules() {
      let self = this;
      self.$vs.loading();
      self
        .findProductById(self.productId)
        .then(product => {
          self.variants = product.data.data.variants;
          if (self.variants.length === 0)
            self.noDataText = "No variants added yet";
          self.$vs.loading.close();
        })
        .catch(err => {
          console.log(err);
          self.$vs.loading.close();
        });
    },
    editVariant(index) {
      this.showCustomError = false;
      this.popupActivo5 = false;
      this.popupActivo5 = true;
      this.variant = this.variants[index];
      delete this.variant.createdAt;
      delete this.variant.updatedAt;
      this.variant.images.forEach(datum => {
        delete datum.createdAt;
        delete datum.updatedAt;
      });
      this.task = "update";
    },
    addVariant() {
      this.showCustomError = false;
      this.popupActivo5 = false;
      this.popupActivo5 = true;
      this.variant = { name: "", description: "", images: [] };
      this.task = "create";
    },
    closePopup() {
      this.popupActivo5 = false;
    },
    addVariantImages(data) {
      if (this.task === "create" && data.length === 1)
        this.variant.images.push({
          image: data[0].Location,
          useAsDefault: true
        });
      else
        data.forEach(datum => {
          this.variant.images.push({
            image: datum.Location,
            useAsDefault: false
          });
        });
    },
    submitVariant(data) {
      let defaultImg = false;
      data.images.forEach(datum => {
        if (datum.useAsDefault) defaultImg = true;
      });
      if (!defaultImg) {
        this.showCustomError = true;
        return false;
      }

      this.showCustomError = false;

      this.$vs.loading();
      this.updateProductVariant({
        id: this.productId,
        data: { variants: [data] }
      })
        .then(response => {
          this.variants = response.data.data;
          this.$vs.loading.close();
          this.popupActivo5 = false;
          this.$vs.notify({
            color: "success",
            text: "Product variant data submitted successfully",
            title: "Success"
          });
        })
        .catch(err => {
          this.$vs.loading.close();
          this.popupActivo5 = false;
          this.$vs.notify({
            color: "danger",
            text: err.data.message,
            title: "Error"
          });
        });
    },
    removeProductImage(imageId, imageUrl, productId) {
      this.$vs.loading({
        background: "#fff",
        color: "#626262",
        container: `#image-${imageId}`,
        scale: 0.45,
        text: "removing"
      });

      let self = this;
      axios
        .get(
          `/api/v1/store/products/deleteImage?imageId=${
            imageId ? imageId : "N/A"
          }&imageUrl=${imageUrl}&productId=${this.productId}&variantId=${
            this.variant._id ? this.variant._id : "N/A"
          }`
        )
        .then(res => {
          if (imageId)
            self.variant.images = self.variant.images.filter(
              value => value._id != imageId
            );
          else
            self.variant.images = self.variant.images.filter(
              value => value.image != imageUrl
            );

          self.$vs.loading.close(`#image-${imageId} > .con-vs-loading`);
        })
        .catch(err => {
          self.$vs.notify({
            title: "Removing Failed",
            text: "Please try again later.",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
          self.$vs.loading.close(`#image-${imageId} > .con-vs-loading`);
        });
    },
    openConfirm(variantId) {
      this.variantIdToDelete = variantId;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure to delete this product variant ?",
        accept: this.deleteVariant
      });
    },
    deleteVariant() {
      this.$vs.loading();
      this.deleteProductVariant({
        productId: this.productId,
        variantId: this.variantIdToDelete
      })
        .then(success => {
          this.variants = success.data.data;
          this.$vs.loading.close();
          this.$vs.notify({
            color: "success",
            title: "Success",
            text: "The product variant was successfully deleted"
          });
        })
        .catch(err => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Failed",
            text: err.data.message
          });
        });
    }
  },
  created() {
    this.loggedInUserType = this.$store.state.AppActiveUser.userType;
    this.productId = this.$route.params.id;
    this.fetchRequiredModules();
  }
};
</script>
