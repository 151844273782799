<template>
  <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
    <vs-popup fullscreen title="Product Variant" :active.sync="popupActivo5">
      <vs-row>
        <vs-col vs-type="flex" vs-align="left" vs-w="12">
          <div class="w-full m-5" align="left">
            <vs-button
              v-if="task === 'update'"
              :disabled="!validateForm"
              class="mr-3"
              @click="saveData"
              icon-pack="feather"
              >Update Variant</vs-button
            >
            <vs-button
              v-else
              :disabled="!validateForm"
              class="mr-3"
              @click="saveData"
              icon-pack="feather"
              >Add Variant</vs-button
            >
            <vs-button color="danger" @click="cancelHandler" icon-pack="feather"
              >Cancel</vs-button
            >
          </div>
        </vs-col>
      </vs-row>
      <vs-form autocomplete="off">
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
        >
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
            <div class="w-full m-5">
              <vs-input
                name="variantName"
                label="Variant Name"
                class="w-full"
                :danger-text="errors.first('variantName')"
                v-model="variant.name"
              />
            </div>
          </vs-col>

          <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="4"
            id="add-more-product-image"
            class="vs-con-loading__container"
          >
            <div style="margin-top: 16px;" class="dateClass">
              <label
                style="display: block;"
                class="vs-input--label custom-label"
                >Upload variant images</label
              >
              <div style="margin-top:2px;">
                <div style="text-align:center;position:relative;">
                  <label
                    id="imagesId"
                    class="image-upload images-upload"
                    style="border:1px solid #cccccc; display:inline-block;width:100%; border-radius: 5px; padding:10px;"
                  >
                    + Attach Images
                    <input
                      type="file"
                      multiple
                      accept="image/*"
                      @change="
                        addVariantImage($event, `add-more-product-image`)
                      "
                      id="file-input"
                      hidden
                    />
                  </label>
                </div>
              </div>
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
            <div class="w-full m-5">
              <label
                style="display: block;"
                class="vs-input--label custom-label"
              >
                Description
              </label>
              <vs-textarea
                v-model="variant.description"
                name="variantDescription"
              />
            </div>
          </vs-col>
        </vs-row>
      </vs-form>

      <vs-row
        v-if="variant.images.length > 0"
        class="mb-2"
        vs-align="flex-start"
        vs-type="flex"
        vs-justify="left"
        vs-w="12"
      >
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <h5 class="mb-2">Note: You must have one default image chosen.</h5>
        </vs-col>
      </vs-row>

      <vs-row v-if="showCustomError && variant.images.length > 0">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <small style="color: darkred;">Please choose a default image</small>
        </vs-col>
      </vs-row>

      <vs-col
        v-for="(item, index) in variant.images"
        :key="index"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="20%"
      >
        <div :id="`image-${item._id}`" class="vs-con-loading__container">
          <div class="mb-6 mr-1">
            <div class="img-div mb-2 relative">
              <img :src="item.image" class="" />
              <div class="image-trash absolute">
                <feather-icon
                  @click="removeImage(item._id, item.image, item.useAsDefault)"
                  icon="Trash2Icon"
                ></feather-icon>
              </div>
            </div>
            <vs-radio
              class=""
              :vs-value="true"
              vs-name="image-radio"
              v-model="item.useAsDefault"
              >Use As Default</vs-radio
            >
          </div>
        </div>
      </vs-col>
    </vs-popup>
  </vs-row>
</template>

<script>
import axios from "@/axios";

export default {
  props: ["popupActivo5", "productId", "variant", "task", "showCustomError"],
  data() {
    return {};
  },
  methods: {
    cancelHandler() {
      this.$emit("closePopup");
    },
    removeImage(id, url, isDefault) {
      if (isDefault) return false;
      this.$emit("removeProductImage", id, url);
    },
    addVariantImage(event, key) {
      this.$vs.loading({
        background: "#fff",
        color: "#626262",
        container: `#add-more-product-image`,
        text: "uploading",
        scale: 0.45
      });
      let self = this;
      let data = new FormData();
      for (let i = 0; i < event.target.files.length; i++)
        data.append("profileImage", event.target.files[i]);

      let config = {
        header: {
          "Content-Type": "image/png"
        }
      };
      axios
        .post("/api/v1/store/products/uploadImages?key=" + key, data, config)
        .then(res => {
          if (res.data.length > 0) {
            this.$emit("addVariantImages", res.data);
          }
          self.$vs.loading.close(`#add-more-product-image > .con-vs-loading`);
        })
        .catch(err => {
          self.$vs.loading.close(`#add-more-product-image > .con-vs-loading`);
          self.$vs.notify({
            title: "Upload Failed",
            text: "Please try again later.",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        });
    },
    saveData() {
      this.$emit("submitVariant", this.variant);
    }
  },
  computed: {
    validateForm() {
      return (
        this.variant.name != "" &&
        this.variant.description != "" &&
        this.variant.images.length != 0 &&
        this.choosedDefaultImage != false
      );
    }
  }
};
</script>

<style scoped>
.img-div {
  width: 150px;
  height: 150px;
}

.img-div img {
  width: 100%;
  height: 100%;
}

.image-trash {
  top: 0;
  cursor: pointer;
  color: #fff;
  background: darkred;
  padding: 2px 5px;
}

.dateClass {
  width: 100%;
}
</style>
